/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* ---------- root variables ---------- */
:root {
  --theme: #e6b7df;
  --theme-light: #f6d3f1;
  --theme-dark: #e7aee7;
  --white: #f1f1f1; 
  --grey: #eaeaea;
  --background: #f7f6f2;
  --text: #333;
}
body {
  margin: 0;
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  padding-top: 9em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* NAV */
.navbar {
  background-color: #f7f6f2;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Set a high z-index to ensure it's above other content */
}

.logo {
  height: 5rem;
  width: auto;
}

.menu-buttons {
  display: flex;
  align-items: center;
}

.menu-buttons button {
  margin-right: 1rem;
  color: white;
  background-color: var(--theme);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
}
.menu-buttons button:hover {
  background-color: var(--theme-dark);
}

.menu-buttons button:last-child {
  margin-right: 0;
}

.contact-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-hover {
  text-decoration: none;
  color: #333;
  position: relative;
  transition: color 0.3s, transform 0.3s;
}

.contact-hover::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme);
  transform: scaleX(0);
  transition: transform 0.3s;
}

.contact-hover:hover {
  color: var(--theme)
}

.contact-hover:hover::before {
  transform: scaleX(1);
}

.close-hover:hover {
  color: var(--theme);
}

.popup-content {
  background-color: white;
  padding: 8em;
  border-radius: 400px;
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 20px;
  color: var(--theme);
  font-size: 3em;
  font-weight: 700;
}

.popup-content button {
  margin-top: 10px;
}



/* Home Page */
.hi-there {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 6em 3em;
}

.hi-there h1 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--theme);
}

.hi-there h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--theme);
}

.hi-there button {
  margin-top: 1em;
  color: white;
  background-color: var(--theme);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-right: 1em;
  font-size: 1rem;
}

.hi-text {
  margin: 3em;
  margin-bottom: 10em;
}

.hi-there img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  border-radius: 50px;
  transition: border-radius 1s;
}



.hi-there img:hover {
  border-radius: 200px; /* Adjust the value as needed for the desired level of rounding */
}



/* About page */
.about-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  place-items: center;
}


.about-banner img {
  height: 30rem;
  width: auto;
  border-radius: 50px;
  transition: border-radius 1s;
}



.about-banner img:hover {
  border-radius: 200px; /* Adjust the value as needed for the desired level of rounding */
}

.about-banner h2 {
  margin-top: 1rem;
  font-size: 4rem;
  font-weight: 800;
  color: #e6b7df;
  text-align: center;
}
.about-banner p {
  font-size: 1.5rem;
  margin-top: 1rem;
  padding: 0 3em;
  text-align: center;
}
.about-text {
  margin: 8em 0;
}

.image-container {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
}



.about-section {
  background-color: var(--theme-light);
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.about-section h2 {
  font-size: 2rem;
  font-weight: 800;
  margin: 0 1rem;
  text-align: center;
  color: var(--text);
}
.about-section img {
  height: 25rem;
  width: auto;
  border-radius: 50px;
  margin: 4em;
  transition: border-radius 1s;
  transform: scaleX(-1);
}
.about-section img:hover {
  border-radius: 200px; /* Adjust the value as needed for the desired level of rounding */
}
.about-section div {
  margin: 2em;
  padding: 2em;
  border-radius: 50px;
  color: var(--text);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.about-section div p {
  font-size: 1.1rem;
  margin-top: 1rem;
  padding: 0 3em;
  text-align: center;
  columns: 2;
}


.about-section button {
  margin-top: 1em;
  color: white;
  background-color: var(--theme);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-right: 1em;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.about-section button:hover {
  background-color: var(--theme-dark);
}


.competences h2 {
  color: var(--theme);
  font-size: 2rem;
  font-weight: 800;
}
.competences {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 2rem;
  padding: 2rem;
}

.competence-list div{
  margin-bottom: 1.5em;
}
.competence-list h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--theme);
}

.toolbox {
  margin-top: 2em;
}

.toolbox h2 {
  color: var(--theme);
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
}

.toolbox p {
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.toolbox-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 2rem;
  padding: 2rem;
}
.category {
  position: relative;
  border-right: 5px solid var(--theme-light); /* Add this line for the vertical border */
  padding-right: 10px; /* Add some padding to separate the border from the content */
}
.category div {
  margin-bottom: 1.5em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.category:last-child {
  border-right: none; /* Remove the right border for the last category */
}



.toolbox button {
  color: white;
  background-color: var(--theme-light);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  margin: 0.5em;
}

/* Projects page */
.projects h1 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--theme);
  text-align: center;
  margin-top: 1em;
}
.projects h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--text);
  text-align: center;
  margin-top: 1.5em;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-top: 2rem;
  max-width: 1000px; /* Adjust the max-width as needed */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4em;
}


.portfolio-item {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  height: 20em;
  width: 20em;
}

.portfolio-item img {
  height: auto;
  border-radius: 50px;
  transition: border-radius 1s;
}

.portfolio-item img:hover {
  border-radius: 100px;
}

.project-title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Prevent the title from intercepting hover events */
}

.portfolio-item:hover .project-title {
  opacity: 1;
}

.portfolio-item:hover img {
  opacity: 0.7; 
}

/* Content pages */
.content {
  margin: 0 3em;
}

.content h1 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--theme);
  text-align: center;
  /* transform: translateY(50%); */
}

.project-top {
  position: relative; /* Needed for absolute positioning */
}

.project-top img {
  width: 80%;
  height: auto;
  display: block; /* Ensures the image behaves as a block element */
  margin: 0 auto; /* Centers the image horizontally */
  border-radius: 10px;
  border-width: 1em;
}
.project-description {
  text-align: center;
  margin-top: 1em;

}

.project-description h2 {
  font-size: 2rem;
  font-weight: 600;
}

.project-description h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.project-description h3::after {
  content: "";
  display: block;
  width: 50px; /* Adjust the width as needed */
  height: 2px; /* Adjust the height as needed */
  background-color: var(--theme-dark); /* Adjust the color as needed */
  margin: 10px auto; /* Adjust the margin as needed */
}

.project-description  button {
  margin: 1em;
  color: white;
  background-color: var(--theme);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
}

.project-about h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2em;
}

.project-image {
  width: 60%;
  height: auto;
  display: block;
  margin: 2em auto;
  border-radius: 20px;
}

.sailmore-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 2rem;
  padding: 2rem;
}

.project-tnt  {
  width: 20%;
  height: auto;
  display: block;
  margin: 2em auto;
  border-radius: 20px;
}

.back-projects {
  margin-top: 1em;
  color: white;
  background-color: var(--theme);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  height: auto;
  display: block;
  margin: 2em auto;
}


/* Footer */
footer {
  background-color: var(--theme);
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-between;
  color: var(--white);
}
.footer-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 150px;
}

.footer-grid h2 {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--white);
}

.direct-contact h2 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--white);
}

.direct-contact h3 {
  text-decoration: underline;
  font-size: 2.5rem;
  font-weight: 600;
}

/* Mobile screen styling */
@media only screen and (max-width: 767px) {
body {
  padding-top: 6em;
}
    .navbar {
      padding: 1rem;

    }
.logo {
  height: 3rem;
}
.menu-buttons button {
  font-size: 0.8rem;
}
    .hi-there {
      display: block;
      margin: 1em 3em;
    }
    .hi-there img {
      width: 100%;
    }
    .hi-text {
      margin-bottom: 1em;
    }


    .about-banner {
      display: block;
      margin: 1em 3em;
    }
    .about-banner img {
      width: 80%;
      height: auto;
      margin: auto;
    }
    .about-banner h2 {
      font-size: 2rem;
    }
    .about-banner p {
      font-size: 1rem;
      padding: 0 1em;
    }

    .toolbox {
      margin-top: 1em;
    }
    .toolbox p {
      margin: 0 3em;
    }

    .toolbox-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin-top: 2rem;
      padding: 2rem;
    }
    .category {
      border-right: none;
    }

    .about-section {
      display: block;
    }
    .about-section img {
      width: 80%;
      height: auto;
      margin: auto;
      padding: 2em;
    }
    .about-section h2 {
      font-size: 1.5rem;
    }
    .about-section div {
      margin: 1em;
      padding: 1em;
      margin-top: 25em;
    }
    .about-section div p {
      font-size: 1rem;
      padding: 0 1em;
    }

    .about-section button {
      margin-top: 1em;
    }
.competences {
      display: block;
      margin: 1em 3em;
    }
    .competences h2 {
      font-size: 2rem;
    }

    .projects h1 {
      font-size: 2rem;
    }
    .projects h2 {
      font-size: 1rem;
    }
    .portfolio-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin-top: 2rem;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4em;
      justify-items: center;
    }
    .portfolio-item {
      height: 15em;
      width: 15em;
      margin-top: 2em;
    }









    .footer-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }


  

}

/* Tablet screen styling */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
 
}

